import './style.css';
import QRCode from 'qrcode';
import { useState, useEffect } from 'react';
import SuccessPopup from '../../modals/SuccessPopup';
import WorkStationtAPI from '../../../api/WorkStationAPI';
import ISearchedWorkStationResponse from '../../../models/response/ISearchedWorkStationResponse';

interface PluginTablePropsTypes{
    checkAreWorkStations?: Function;
}

const PluginTable = (props: PluginTablePropsTypes) => {
    const { checkAreWorkStations } = props;

    const [renderPlugins, setRenderPlugins] = useState<ISearchedWorkStationResponse[]>([]);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState<boolean>(false);
    const [curPage, setCurPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [lastPage, setLastPage] = useState<number>(0);

    const createQRCodeImg = (text: string, id: number) => {
        let reportUrl = '';
        QRCode.toDataURL(text, function (err: any, url: string) {
            if(err) console.log(err)
            if (url) reportUrl = url
            return url
        });

        const fileName = `${id}QR-code.png`;
        const downloadElement = document.createElement("a");
        downloadElement.href = reportUrl;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(reportUrl);
    }

    const handleDownloadQR = (text: string, id: number) => {
        createQRCodeImg(text, id);
    }

    const updateWorkStation = (id: number) => {
        WorkStationtAPI.prolongation(id)
            .then(() => getPlugins(0))
            .catch(error => console.log(error))
    }

    const handleProlongation = (id: number) => {
        updateWorkStation(id); 
        setCurPage(0); 
    }

    const getPlugins = (currentPage: number, scroll?: boolean) => {
        WorkStationtAPI.getPlugins(currentPage, size)
            .then(response => {
                const data = (response.data as ISearchedWorkStationResponse[]);
                if (scroll) {
                    setRenderPlugins([...renderPlugins, ...data])
                } else {
                    setRenderPlugins(data);
                }

                if(checkAreWorkStations) checkAreWorkStations(true)

                if (data.length > 0) {
                    setLastPage(currentPage + 1);
                } else {
                    if (!renderPlugins.length) {
                        if(checkAreWorkStations) checkAreWorkStations(false);
                    }
                    setLastPage(currentPage);                
                }
            })
            .catch(() => {})
    }

    const handleCopy = (id: number) => {
        const textField = document.createElement('input');
        document.body.appendChild(textField);
        textField.value = `https://api.time-money.shop/redirect?id=${id}`
        textField.select();
        document.execCommand('copy');
        document.body.removeChild(textField);
        setIsSuccessPopupVisible(true);
    }

    const onScrollList = (event: any) => {
        const scrollBottom = Math.abs(Math.ceil(event.target.scrollHeight) - Math.ceil(event.target.scrollTop) - Math.ceil(event.target.offsetHeight)) < 20;   

        if (scrollBottom && curPage < lastPage) {
            const nextPage = curPage + 1;
            setCurPage(nextPage);
            getPlugins(nextPage, true);
        }
    };

    useEffect(() => {
        setCurPage(0);
        setLastPage(0);
        getPlugins(0);
    }, []);

    return (
        <div 
            className='table-container'
            onScroll={(e: React.UIEvent<HTMLElement>) => onScrollList(e)}
        >
            <table>
                <thead>
                    <tr>
                        <th>имя плагина</th>
                        <th>изготовлено документов</th>
                        <th>заполнено приложений</th>
                        <th>плагин ID</th>
                        <th>секретный код плагина</th>
                        <th>QR-code</th>
                        <th>действителен до:</th>
                        <th>продлить</th>
                        <th>ссылка на подключение</th>
                    </tr>
                </thead>
                <tbody>
                    {renderPlugins.length > 0
                        ? (renderPlugins.map((plugin, index) =>
                            <tr key={plugin.name + index + plugin.textQr}>
                                <td key={plugin.name + plugin.textQr + index}>{plugin.name}</td>
                                <td key={plugin.textQr + plugin.name + index}>{plugin.producedDocuments}</td>
                                <td key={plugin.name}>{plugin.filledApplications}</td>
                                <td key={plugin.name + plugin.id}>{plugin.id}</td>
                                <td key={plugin.name + plugin.secretId}>{plugin.secretId}</td>
                                <td key={plugin.name + 'a1'}>
                                    <a
                                        key={plugin.name + 'b1'}
                                        onClick={() => handleDownloadQR(plugin.textQr, plugin.id)}
                                    >
                                        СКАЧАТЬ
                                    </a>
                                </td>
                                <td key={plugin.name + 'c1'}>
                                    {(new Date(plugin.expiredAt)).toLocaleDateString()}
                                </td>
                                <td key={plugin.name + 'd1'}>
                                    <a 
                                        key={plugin.name + plugin.textQr}
                                        onClick={() => handleProlongation(plugin.id)}
                                    >
                                        ПРОДЛИТЬ
                                    </a>
                                </td>
                                <td key={plugin.name + 'f1'}>
                                    <a 
                                        key={plugin.textQr + plugin.name}
                                        onClick={() => handleCopy(plugin.id)}
                                    >
                                        СКОПИРОВАТЬ
                                    </a>
                                </td>
                            </tr>
                        ))
                        : (<tr>
                            <td 
                                colSpan={9} 
                                style={{ width: '100%', border: 'none', columnSpan: 'all', textAlign: 'center' }}
                            >
                                Ничего нет
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>

            {isSuccessPopupVisible
                ? <SuccessPopup
                    message={'Ссылка скопирована'}
                    onClose={() => setIsSuccessPopupVisible(false)}
                />
                : null
            }
        </div>
    )
}

export default PluginTable;
