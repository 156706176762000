import './style.css';
import Preloader from '../Preloader';
import PluginTable from '../PluginTable';
import { useEffect, useState } from 'react';
import ErrorPopup from '../../modals/ErrorPopup';
import SuccessPopup from '../../modals/SuccessPopup';
import WorkStationtAPI from '../../../api/WorkStationAPI';

const CreatePluginModal = () => {
    const [name, setName] = useState<string>('');
    const [isPreloader, setIsPreloader] = useState<boolean>(false);
    const [isTableVisible, setIsTableVisible] = useState<boolean>(false);
    const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState<boolean>(false);
    const [isOpenErrorPopup, setIsOpenErrorPopup] = useState<boolean>(false);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

    const sendCreateReq = () => {
        setIsTableVisible(false);
        WorkStationtAPI.create(name)
            .then(() => {
                openModal(0);
                setTimeout(() => {
                    setIsTableVisible(true);
                }, 2000);
            })
            .catch(() => {
                openModal(1);
            })
    }

    // 0 - successpopup 1 - errorpopup
    const openModal = (targetPopup: number) => {
        targetPopup
            ? setIsOpenErrorPopup(true)
            : setIsOpenSuccessPopup(true)
    }

    const handleBtnClick = () => {
        setName('');
        setIsPreloader(true);
        setTimeout(() => {
            if (name) {
                setTimeout(() => setIsTableVisible(false))
                sendCreateReq();
            }
            setIsTableVisible(!isTableVisible);
        }, 1000);
    }

    useEffect(() => {
        if (isPreloader) {
            setTimeout(() => {
                setIsPreloader(false);
            }, 2000);
        }
    }, [isPreloader]); 

    return (
        <div>
            <div className="plugin-modal">
                <h3>СОЗДАНИЕ ПЛАГИНА </h3>
                <p>Пожалуйста введите имя плагина или его порядковый номер:</p>
                <input
                    type="text"
                    value={name}
                    onInput={handleNameChange}
                />
                <button onClick={handleBtnClick}>СКАЧАТЬ</button>
            </div>
            {isTableVisible
                ? <PluginTable/>
                : isPreloader
                    ? <Preloader/>
                    : null
            }

            {isOpenSuccessPopup
                ? <SuccessPopup 
                    message='Плагин успешно создан' 
                    onClose={() => setIsOpenSuccessPopup(false)}
                />
                : null
            }

            {isOpenErrorPopup
                ? <ErrorPopup 
                    error='Ошибка! Плагин уже существует.' 
                    onClose={() => setIsOpenErrorPopup(false)}
                />
                : null
            }
        </div>
    )
}

export default CreatePluginModal;
