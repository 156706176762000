const isKPPValid = (orgKPP: string) => {
    const kppRegex = /^\d{9}$|^\d{9}$/;

    if (kppRegex.test(orgKPP)) {
        return false;
    } else {
        return true;
    }
}  

export default isKPPValid;
